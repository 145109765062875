import React from 'react'
import { motion } from 'framer-motion'

import './Advantages.scss'

const Advantages = () => {
	const advatantagesTitleVariants = {
		initial: {
			opacity: 0,
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	const advatantagesTextVariants = {
		initial: {
			x: '-10px',
		},
		animated: {
			x: '10px',
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	return (
		<section
			className='advantages'
			id='advantages'>
			<div className='advantages__content'>
				<motion.span
					className='advantages__title'
					variants={advatantagesTitleVariants}
					initial='initial'
					whileInView='animated'>
					Играть с нами всегда <b>Весело!</b>
				</motion.span>
				<p
					className='advantages__text'
					variants={advatantagesTextVariants}
					initial='initial'
					whileInView='animated'>
					Мы гарантируем, что каждая игра будет увлекательным
					путешествием, которое объединит вашу команду и оставит яркие
					впечатления.
				</p>
				<div className='advantages__cards'>
					<div className='advantages__card'>
						<img
							className='advantage__icon'
							src='assets/img/icon_positive.png'
						/>
						<span className='advantage__title'>
							Дружелюбная мафия
						</span>
						<p className='advantage__text'>
							В нашем клубе царит дружелюбная и позитивная
							атмосфера, а также мы стараемся не использовать
							слова «убит», «жертва» и тд.
						</p>
					</div>
					<div className='advantages__card'>
						<div className='advantage__automat' />
						<span className='advantage__title'>
							Антуражность игры
						</span>
						<p className='advantage__text'>
							За нашими игровыми столами вы почувствуете себя
							настоящими мафиози, и после игры сможете сделать
							стильное фото с реквизитом
						</p>
					</div>
					<div className='advantages__card'>
						<img
							className='advantage__icon'
							src='assets/img/icon_masks.png'
						/>
						<span className='advantage__title'>
							Разнообразие ролей
						</span>
						<p className='advantage__text'>
							В зависимости от количества игроков за столом, в
							игре принимают участие разные роли и мы постоянно
							экспереметируем внедряя в нашу игру новые роли
						</p>
					</div>
					<div className='advantages__card'>
						<img
							className='advantage__icon'
							src='assets/img/icon__loyalty.png'
						/>
						<span className='advantage__title'>
							Уникальная программа лояльности
						</span>
						<p className='advantage__text'>
							Мы всегда рады новым игроками, поэтому и новички и
							опытные игроки получают различные бонусы
						</p>
					</div>
					<div className='advantages__card'>
						<img
							className='advantage__icon'
							src='assets/img/icon_statistic.png'
						/>
						<span className='advantage__title'>
							Исчерпывающая статистика игр
						</span>
						<p className='advantage__text'>
							В нашем клубе ведется полная статистика всех игр,
							поэтому будьте уверены, что любой ваш выстрел или
							лечение не останется незамеченным
						</p>
					</div>
					<div className='advantages__card'>
						<img
							className='advantage__icon'
							src='assets/img/icon_culture.png'
						/>
						<span className='advantage__title'>Культура игры</span>
						<p className='advantage__text'>
							Все наши игроки с уважением относятся к опытным
							игрокам и с радостью готовы принять новичков в нашу
							мафиозную семью
						</p>
					</div>
				</div>
			</div>
		</section>
	)
}
export default Advantages
