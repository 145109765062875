import React from 'react'
import { Link, animateScroll as scroll } from 'react-scroll'

import './Navigation.scss'

const Navigation = () => {
	return (
		<nav className='navigation'>
			<div className='navigation__content'>
				<Link
					className='navigation__link'
					to='header'
					spy={true}
					smooth={true}
					duration={500}>
					Главная
				</Link>
				<Link
					className='navigation__link'
					to='schedule'
					spy={true}
					smooth={true}
					duration={500}>
					Расписание
				</Link>
				<Link
					className='navigation__link'
					to='advantages'
					spy={true}
					smooth={true}
					duration={500}>
					Наши преимущества
				</Link>
				<Link
					className='navigation__link'
					to='about'
					spy={true}
					smooth={true}
					duration={500}>
					О нас
				</Link>

				<Link
					className='navigation__link'
					to='form'
					spy={true}
					smooth={true}
					duration={500}>
					Задать вопрос
				</Link>
				<a
					href='tel:+79037552612'
					className='navigation__telephone'>
					+ 7 (903) 755-26-12
				</a>
			</div>
		</nav>
	)
}

export default Navigation
