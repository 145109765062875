import React, { useState } from 'react'
import { color, motion } from 'framer-motion'
import { Link, animateScroll as scroll } from 'react-scroll'

import Navigation from './Navigation/Navigation'

import TelegramIcon from '@mui/icons-material/Telegram'
import InstagramIcon from '@mui/icons-material/Instagram'

import logo from '../logo.png'
import lettering from '../lettering.png'

import './Header.scss'

const Header = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [logoSrc, setLogoSrc] = useState('/assets/img/logo.png')
	const letteringSrc = '/assets/img/lettering.png'

	const headerLogoVariants = {
		initial: {
			opacity: 0,
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	const headerLetteringVariants = {
		initial: {
			opacity: 0,
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	const headerNavVariants = {
		initial: {
			opacity: 0,
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	const navVariants = {
		initial: {
			height: 0,
		},
		animated: {
			height: '1000px',
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 1,
			},
		},
	}

	return (
		<header
			className='header'
			id='header'>
			<Navigation />
			<motion.div className='header__bottom'>
				<div
					className={
						isOpen
							? 'header__bottom-content_open'
							: 'header__bottom-content_close'
					}>
					<div className='header__main'>
						<motion.img
							variants={headerLogoVariants}
							initial='initial'
							whileInView='animated'
							src={logo}
							alt='Московская мафия'
							className='header__logo'
						/>
						<motion.img
							variants={headerLetteringVariants}
							initial='initial'
							whileInView='animated'
							src={lettering}
							alt='Клуб московская мафия'
							className='header__lettering'
						/>
					</div>

					<div
						className={
							isOpen
								? 'header__burger_open'
								: 'header__burger_close'
						}
						onClick={() => {
							setIsOpen(!isOpen)
						}}></div>
					<div className='header__bottom-icons'>
						<a
							href='tel:+79037552612'
							className='header__phone-number'>
							+ 7 (903) 755-26-12
						</a>
						<a
							target='_blank'
							href='https://t.me/Moskovskaya_Mafiya'
							className='header__social-icon'>
							<svg
								width='24'
								height='21'
								viewBox='0 0 28 21'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M23.5988 1.12111L19.9923 19.9492C19.9923 19.9492 19.4877 21.2546 18.1015 20.6285L9.78032 14.0215L9.74173 14.0021C10.8657 12.9569 19.5817 4.84163 19.9626 4.47378C20.5523 3.90407 20.1863 3.56491 19.5016 3.99527L6.62698 12.462L1.65999 10.7314C1.65999 10.7314 0.878337 10.4434 0.80314 9.81736C0.726953 9.19028 1.68572 8.85111 1.68572 8.85111L21.9346 0.625175C21.9346 0.625175 23.5988 -0.132047 23.5988 1.12111Z'
									fill='white'
								/>
							</svg>
						</a>
						<a
							href='https://vk.com/moskovskaya_mafiya'
							target='_blank'
							className='header__social-icon'>
							<svg
								width='24'
								height='21'
								viewBox='0 0 28 15'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M15.1778 17.4016C6.15798 17.4016 1.01319 11.0953 0.798828 0.601562H5.317C5.46541 8.30366 8.79633 11.5661 11.4347 12.2388V0.601562H15.689V7.2442C18.2944 6.95832 21.0317 3.93129 21.9551 0.601562H26.2094C25.8615 2.32845 25.1679 3.96355 24.172 5.40456C23.1761 6.84558 21.8993 8.06152 20.4216 8.97634C22.0711 9.81223 23.528 10.9954 24.6962 12.4477C25.8645 13.9 26.7174 15.5884 27.1988 17.4016H22.5158C22.0836 15.8267 21.2053 14.4169 19.9909 13.349C18.7765 12.281 17.28 11.6023 15.689 11.398V17.4016H15.1778Z'
									fill='white'
								/>
							</svg>
						</a>
						<a
							href='https://www.instagram.com/moscow__mafia.ru/'
							target='_blank'
							className='header__social-icon'>
							<InstagramIcon />
						</a>
					</div>
					<motion.div
						variants={navVariants}
						animate={isOpen ? 'open' : 'close'}
						className={
							isOpen
								? 'header__bottom-navigation_open'
								: 'header__bottom-navigation_close'
						}>
						<a
							className='navigation__link'
							onClick={() => {
								setIsOpen(!isOpen)
							}}
							href='#header'>
							Главная
						</a>
						<a
							className='navigation__link'
							onClick={() => {
								setIsOpen(!isOpen)
							}}
							href='#schedule'>
							Расписание
						</a>
						<a
							className='navigation__link'
							onClick={() => {
								setIsOpen(!isOpen)
							}}
							href='#advantages'>
							Наши преимущества
						</a>
						<a
							className='navigation__link'
							onClick={() => {
								setIsOpen(!isOpen)
							}}
							href='#about'>
							О нас
						</a>
						<a
							className='navigation__link'
							onClick={() => {
								setIsOpen(!isOpen)
							}}
							href='#form'>
							Задать вопрос
						</a>
					</motion.div>
					<div
						className={
							isOpen
								? 'header__navigation-icons_open'
								: 'header__navigation-icons_close'
						}>
						<a
							href='#'
							className='header__phone-number'>
							+ 7 (903) 755-26-12
						</a>
						<div className='header__navigation-social-icons'>
							<a
								target='_blank'
								href='https://t.me/Moskovskaya_Mafiya'
								className='header__social-icon'>
								<svg
									width='24'
									height='21'
									viewBox='0 0 28 21'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M23.5988 1.12111L19.9923 19.9492C19.9923 19.9492 19.4877 21.2546 18.1015 20.6285L9.78032 14.0215L9.74173 14.0021C10.8657 12.9569 19.5817 4.84163 19.9626 4.47378C20.5523 3.90407 20.1863 3.56491 19.5016 3.99527L6.62698 12.462L1.65999 10.7314C1.65999 10.7314 0.878337 10.4434 0.80314 9.81736C0.726953 9.19028 1.68572 8.85111 1.68572 8.85111L21.9346 0.625175C21.9346 0.625175 23.5988 -0.132047 23.5988 1.12111Z'
										fill='white'
									/>
								</svg>
							</a>
							<a
								href='https://vk.com/moskovskaya_mafiya'
								target='_blank'
								className='header__social-icon'>
								<svg
									width='24'
									height='21'
									viewBox='0 0 28 15'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M15.1778 17.4016C6.15798 17.4016 1.01319 11.0953 0.798828 0.601562H5.317C5.46541 8.30366 8.79633 11.5661 11.4347 12.2388V0.601562H15.689V7.2442C18.2944 6.95832 21.0317 3.93129 21.9551 0.601562H26.2094C25.8615 2.32845 25.1679 3.96355 24.172 5.40456C23.1761 6.84558 21.8993 8.06152 20.4216 8.97634C22.0711 9.81223 23.528 10.9954 24.6962 12.4477C25.8645 13.9 26.7174 15.5884 27.1988 17.4016H22.5158C22.0836 15.8267 21.2053 14.4169 19.9909 13.349C18.7765 12.281 17.28 11.6023 15.689 11.398V17.4016H15.1778Z'
										fill='white'
									/>
								</svg>
							</a>
							<a
								href='https://www.instagram.com/moscow__mafia.ru/'
								target='_blank'
								className='header__social-icon'>
								<InstagramIcon />
							</a>
						</div>
					</div>
				</div>
			</motion.div>
		</header>
	)
}

export default Header
