import React from 'react'
import { useState } from 'react'
import { motion } from 'framer-motion'

import './Schedule.scss'

const Schedule = (props) => {
	const [currentDay, setCurrentDay] = useState('sr')
	const scheduleTitleVariants = {
		initial: {
			opacity: 0,
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	const days = {
		sr: (
			<div
				className='schedule__card'
				ref={props.ref}>
				<div className='schedule__img_strekoza'></div>
				<p>
					<span>Начало игры:</span>
					<br />
					<span>Среда 19:30</span>
				</p>
				<p>
					<span>Адрес проведения:</span>
					<br />
					<span>
						Белая стрекоза, ул. Большая Дмитровка, 32, стр. 4
					</span>
				</p>
				<a
					href='https://t.me/moscow_mafia_club'
					target='_blank'>
					Записаться
				</a>
			</div>
		),
		sb: (
			<div className='schedule__card'>
				<div className='schedule__img_msk'></div>
				<p>
					<span>Начало игры:</span>
					<br />
					<span>Суббота, 19:00</span>
				</p>
				<p>
					<span>Адрес проведения:</span>
					<br />
					<span>МСК Lounge, просп. Вернадского, 14А, 3 этаж</span>
				</p>
				<a
					href='https://t.me/moscow_mafia_club'
					target='_blank'>
					Записаться
				</a>
			</div>
		),
	}

	const d = (day) => {
		let result = ''
		if (Object.hasOwn(days, day)) result += 'schedule__tab_enable'
		else result += 'schedule__tab_disable'
		if (currentDay === day) result += ' active'
		return result
	}
	return (
		<section
			className='schedule section'
			id='schedule'>
			<div className='schedule__content'>
				<motion.span
					className='schedule__title'
					variants={scheduleTitleVariants}
					initial='initial'
					whileInView='animated'>
					<b>Расписание</b> наших игры
				</motion.span>
				<p className='schedule__text'>
					Каждый день мы встречаемся в разных локациях, следите за
					нашим расписанием на сайте
				</p>
				<div className='schedule__tabs'>
					<span className={d('pn')}>Пн</span>
					<span className={d('vt')}>Вт</span>
					<span
						className={d('sr')}
						onClick={() => {
							setCurrentDay('sr')
						}}>
						Cр
					</span>
					<span className={d('cht')}>Чт</span>
					<span className={d('pt')}>Пт</span>
					<span
						className={d('sb')}
						onClick={() => {
							setCurrentDay('sb')
						}}>
						Сб
					</span>
					<span className={d('vs')}>Вс</span>
				</div>
				<div className='schedule__tab-content'>
					{days[currentDay]}
					{/* <div className='schedule__card'>
						<div className='schedule__img'></div>
						<p>
							<span>Начало игры:</span>
							<br />
							<span>Пн, 22 аперля, 18:00</span>
						</p>
						<p>
							<span>Адрес проведения:</span>
							<br />
							<span>
								МСК Lounge, просп. Вернадского, 14А, 3 этаж
							</span>
						</p>
						<a href='#'>Записаться</a>
					</div> */}
					{/* <div className='schedule__card'>
						<div className='schedule__img'></div>
						<p>
							<span>Начало игры:</span>
							<br />
							<span>Пн, 22 аперля, 18:00</span>
						</p>
						<p>
							<span>Адрес проведения:</span>
							<br />
							<span>
								МСК Lounge, просп. Вернадского, 14А, 3 этаж
							</span>
						</p>
						<a href='#'>Записаться</a>
					</div> */}
				</div>
			</div>
		</section>
	)
}

export default Schedule
