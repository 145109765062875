import { useRef } from 'react'

import Header from './Header/Header'
import Slider from './Slider/Slider'
import Schedule from './Schedule/Schedule'
import Advantages from './Advantages/Advantages'
import About from './About/About'
import Form from './Form/Form'
import Footer from './Footer/Footer'

import './App.scss'

const App = () => {
	return (
		<>
			<Header />
			<Slider />
			<Schedule />
			<Advantages />
			<About />
			<Form />
			<Footer />
		</>
	)
}

export default App
