import React from 'react'
import { useState } from 'react'
import { motion } from 'framer-motion'

import './Form.scss'

const Form = () => {
	const [name, setName] = useState('')
	const [nameClass, setNameClass] = useState('')
	const [phone, setPhone] = useState('')
	const [phoneClass, setPhoneClass] = useState('')
	const [question, setQuestion] = useState('')
	const [buttonClass, setButtonClass] = useState('')
	const [questionClass, setQuestionClass] = useState('')
	const [isCorrectClass, setIsCorrectClass] = useState('form__hidden-text')

	const formTitleVariants = {
		initial: {
			opacity: 0,
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	const handleSubmit = (e) => {
		let token = '7050966645:AAGghFvHzoHUZE-20valZ2NfIEF6DpWnhjM'
		let chat_id = -4116568703
		let result = `<b>Имя:</b> ${name}%0A<b>Телефон:</b>${phone}%0A<b>Вопрос:</b>${question}`
		let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${result}&parse_mode=html`
		if (!name) {
			setIsCorrectClass('form__visible-text')
			setNameClass('incorrect')
			e.preventDefault()
			return
		}
		setNameClass('')
		if (!phone) {
			setIsCorrectClass('form__visible-text')
			setPhoneClass('incorrect')
			e.preventDefault()
			return
		}
		setPhoneClass('')
		if (!question) {
			setIsCorrectClass('form__visible-text')
			setQuestionClass('incorrect')
			e.preventDefault()
			return
		}
		setQuestionClass('')
		setIsCorrectClass('form__hidden-text')
		let api = new XMLHttpRequest()
		api.open('GET', url, true)
		api.send()
		// this.setState({
		// 	isSend: true,
		// 	cardRightClass:
		// 		'pop-ask-card-right animate__animated animate__flipOutY',
		// 	cardSuccessClass: 'success animate__animated animate__flipInY',
		// })
		setTimeout(() => {
			setButtonClass('correct-button')
		}, 500)

		e.preventDefault()
	}

	return (
		<section
			className='form'
			id='form'>
			<div className='form__content'>
				<motion.span
					className='form__title'
					variants={formTitleVariants}
					initial='initial'
					whileInView='animated'>
					У вас остались <b>вопросы?</b>
				</motion.span>
				<p className='form__text'>
					У вас остались вопросы, не стесняйтесь задавать их нам. Мы
					всегда рады помочь и ответить на ваши вопросы
				</p>
				<form onSubmit={handleSubmit}>
					<input
						type='text'
						onChange={(e) => {
							setName(e.target.value)
						}}
						placeholder='Ваше имя'
						className={nameClass}
					/>
					<input
						type='text'
						onChange={(e) => {
							setPhone(e.target.value)
						}}
						placeholder='+7 (999) 999-99-99'
						className={phoneClass}
					/>
					<textarea
						placeholder='Напишите ваш вопрос'
						onChange={(e) => {
							setQuestion(e.target.value)
						}}
						className={questionClass}
					/>
					<button type='submit'>Задать вопрос</button>

					<span>
						Нажимая на кнопку, вы даете согласие на обработку
						персональных данных
					</span>
					<span className={isCorrectClass}>
						Пожалуйста заполните обязательные поля
					</span>
					<span
						className={
							buttonClass === 'correct-button'
								? 'correct-text-visible'
								: 'correct-text-hidden'
						}>
						Ваш вопрос отправлен! Мы свяжемся с вами в самое
						ближайщее время.
					</span>
				</form>
			</div>
		</section>
	)
}

export default Form
