import React from 'react'
import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import './Slider.scss'

const Slider = () => {
	const [currentSlide, setCurrentSlide] = useState(0)
	const slides = [
		{
			title: (
				<>
					Мы самая <b>добродушная</b> мафия
				</>
			),
			text: 'Мы очень рады новым друзьям! У нас в клубе вы сможете почувствовать себя как дома',
		},
		{
			title: (
				<>
					Приводи друзей и получи бонус <b>5000 рублей</b>
				</>
			),
			text: 'Только в июле! Успей поучавствовать в уникальной акции и получи 5000 рублей!',
		},
		{
			title: (
				<>
					В нашем клубе вы можете заказать <b>любимую карту</b>
				</>
			),
			text: 'Мы предлагаем услугу заказа карты. Вы можете заказать карту, и вы гарантировано получите ее',
		},
	]

	const handleSlide = (index) => {
		setCurrentSlide(index)
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			setCurrentSlide((currentSlide + 1) % 3)
		}, 4000)
	})

	const sliderTitleVariants = {
		initial: {
			opacity: 0,
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	const sliderTextVariants = {
		initial: {
			opacity: 0,
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	return (
		<section className='slider'>
			<div className='slider__left'></div>
			<div className='slider__right'>
				{/* <div className='slider__dark'></div> */}
				<div
					className={`image${currentSlide}`}
					alt=''
				/>
			</div>
			<div className='slider__content'>
				<motion.span
					className='slider__title'
					variants={sliderTitleVariants}
					initial='initial'
					whileInView='animated'>
					{slides[currentSlide].title}
				</motion.span>
				<motion.p
					className='slider__text'
					variants={sliderTextVariants}
					initial='initial'
					whileInView='animated'>
					{slides[currentSlide].text}
				</motion.p>
				<a
					href='https://t.me/Moskovskaya_Mafiya'
					target='_blank'
					className='slider__button'>
					Вступить в клуб
				</a>
				{/* <div className='slider__pagination'>
					<div
						className={currentSlide == 0 ? 'active' : null}
						onClick={() => {
							handleSlide(0)
						}}></div>
					<div
						className={currentSlide == 1 ? 'active' : null}
						onClick={() => {
							handleSlide(1)
						}}></div>
					<div
						className={currentSlide == 2 ? 'active' : null}
						onClick={() => {
							handleSlide(2)
						}}></div>
				</div> */}
			</div>
		</section>
	)
}

export default Slider
