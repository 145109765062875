import React from 'react'
import { motion } from 'framer-motion'

import './About.scss'

const About = () => {
	const sectionTitleVariants = {
		initial: {
			opacity: 0,
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	const textVariants = {
		initial: {
			opacity: 0,
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	const automatVariants = {
		initial: {
			opacity: 0,
		},
		animated: {
			opacity: 1,
			transition: {
				repeat: 0,
				delay: 0.3,
				duration: 0.5,
			},
		},
	}

	return (
		<section
			className='about'
			id='about'>
			<motion.div
				variants={automatVariants}
				initial='initial'
				whileInView='animated'
				className='automat'
			/>
			<div className='about__content'>
				<motion.h1
					className='about__title'
					variants={sectionTitleVariants}
					initial='initial'
					whileInView='animated'>
					Поиграем в мафию в <b>Москве!</b>
				</motion.h1>
				<div className='about__text-content'>
					<motion.p
						className='first'
						variants={textVariants}
						initial='initial'
						whileInView='animated'>
						<h2>
							<b>Поиграть в мафию с друзьями.</b>
						</h2>
						Если Вы поставили себе задачу интересно поиграть в мафию
						в Москве, да еще и с друзьями в хорошей компании, тогда
						вам точно к Нам. Почему? Да потому что у нас очень
						позитивно, весело и увлекательно. Наш клуб мафии и
						организовывался специально для Вас, не верите? Приходите
						- и убедитесь!
					</motion.p>
					<motion.p
						className='second'
						variants={textVariants}
						initial='initial'
						whileInView='animated'>
						<h2>
							<b>Клуб мафии в Москве.</b>
						</h2>
						Клуб "Московская мафия" позволяет комфортно поиграть в
						городскую мафию и новичкам, и опытным игрокам. Ведущий
						очень постарается сделать так, чтобы всем было интересно
						за игровым столом.
					</motion.p>
					<motion.p
						className='third'
						variants={textVariants}
						initial='initial'
						whileInView='animated'>
						<h2>
							<b>Поиграть в мафию бесплатно это НЕ про нас.</b>
						</h2>
						Любая хорошая организация игры не бывает простой, а
						значит бесплатной. Мы сделали выбор в пользу качества
						проводимых нами игр и предлагаем вам сделать тоже самое!
					</motion.p>
				</div>
			</div>
		</section>
	)
}

export default About
